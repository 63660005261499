export interface RouteInterface {
    path: string;
    component: React.ReactNode;
    name: string;
    exact?: boolean;
}

export interface AbbResponseType {
    status: number;
    details?: any;
}

export interface IAuthHelperModel {
    getToken(): string;
    getTenantName(): string;
    logout(): void;
    keepLoginSession(): void;
    switchTenant(tenantName: string): void;
}
export const commonAuthHelper: IAuthHelperModel = {
    getToken: () => "",
    getTenantName: () => "",
    logout: () => "",
    keepLoginSession: () => "",
    switchTenant: (tenantName: string) => ""
};