import { IFilterQuery, IStructure } from "../../store/assets/types";

// RoutePath Constants

export const ROUTE_PATHNAME = {
    HOME: "/models",
    OBJECTS: "/assets",
    FUNCTIONS: "/functions",
    SETTINGS: '/settings',
};
// ! ASSETS PAGE

export enum INSTANCE_CONFIG_MODELS_TAB {
    ALL = "All",
    MATCHING = "Matching",
}

export const ASSET_CATEGORY_TYPES = {
    SYSTEM: {
        value: "Systems",
        name: "Systems",
    },
    EDGE: {
        value: "Edge",
        name: "Edge",
    },
};

// ! MODELS PAGE

export const MODEL_TYPES = {
    OBJECTS: {
        value: "objects",
        label: "Object Types",
        camelCaseLabel: "Objects",
    },
    FUNCTIONS: {
        value: "functions",
        label: "Functions",
        camelCaseLabel: "Functions",
    },
};

// ! FUNCTIONS PAGE

// ! CONFIGURATION TOOL

export const CANVAS_DIMENSION = {
    WIDTH: window.screen.width - 235 - 20, //- window.screen.width * .13,
    HEIGHT: window.innerHeight - 188, // window.screen.height * .46
};

export const VIEW_MODE = {
    TABLE: {
        value: "tableView",
        label: "Table View",
    },
    DIAGRAM: {
        value: "diagramView",
        label: "Diagram View",
    },
};

export const DRAGGABLE_ITEMS = {
    TABLE_VIEW: {
        asset: {
            src: "TABLE_ASSET_DRAG_SOURCE",
            target: "TABLE_ASSET_DRAG_TARGET",
        },
        ioVariables: {
            src: "TABLE_IO_VARIABLE_DRAG_SOURCE",
            target: "TABLE_IO_VARIABLE_DRAG_SOURCE",
        },
    },
    DIAGRAM_VIEW: {
        canvas: {
            src: "CANVAS_ASSET_DRAG_SOURCE",
            target: "CANVAS_ASSET_DRAG_SOURCE",
        },
    },
};

export const ROOT_LEVEL_ID = 1;
export const DEFAULT_TREE_LIST_NODE = {
    name: "ROOT",
    level: ROOT_LEVEL_ID,
    children: [],
    ancestorLevel: "",
    loading: false,
};

export const DEFAULT_TREE_VIRTUAL_LIST_NODE = {
    id: ROOT_LEVEL_ID,
    name: "ROOT",
    state: {},
    children: [],
};

export const GROUP_BY_LIST: string[] = ["Structure"];

export const DEFAULT_ASSET_STRUCTURE_DETAILS = {
    structureList: [],
    systemList: [],
    groupByList: GROUP_BY_LIST,
    selectedStructure: undefined,
    selectedSystem: undefined,
    selectedGroupBy: "",
    searchText: "",
    structureListForTenant: []
};

export const DEFAULT_FILTER_QUERY: IFilterQuery = {
    applyFilter: false,
    filteredItems: [],
    filterOptions: [],
    assetModelTypes: [],
    assetTypes: [],
    isFilterLoading: false,
};

export const OBJECT_SCALE_LIMIT = {
    MAX_VALUE: 1.5,
    MIN_VALUE: 1,
};

export const FUNCTION_TIME_TRIGGER_RANGE = {
    MIN_VALUE: 0,
    MAX_VALUE: 28800,
};

export const DELETE_MODEL_TYPE = {
    MODEL: "MODEL",
    OBJECT_FUNC: "OBJECT_FUNC",
    CONNECTION_LINE: "CONNECTION_LINE",
};

export const CONFIGURATION_TOOL_MODE = <const>{
    VIEW: "VIEW",
    EDIT: "EDIT",
    CREATE: "CREATE",
};

export type CONFIGURATION_TOOL_MODE_CHILDREN =
    | typeof CONFIGURATION_TOOL_MODE.VIEW
    | typeof CONFIGURATION_TOOL_MODE.EDIT
    | typeof CONFIGURATION_TOOL_MODE.CREATE;

// INSTANCE CONFIG

export const CONFIGURATION_STATUS = <const>{
    YET_TO_BE_CONFIGURED: "Not configured",
    ENABLED: "Enabled",
    DISABLED: "Disabled",
};

// ! OTHERS
export enum SAVE_OPTIONS {
    PUBLISH = "Published",
    DRAFT = "Draft",
}

export const LOADER_TYPE = <const>{
    RADIAL: "radial",
    BAR: "bar",
};

export const LOADER_SIZE = <const>{
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const NOTIFICATION_MODAL_STATUS = {
    ERROR: "error",

    WARNING: "warning",

    SUCCESS: "success",
};

export const SUPPORTED_MODEL = <const>{
    MODEL_ID: "abb.ability.model.supportedModels",
    TYPE_ID: "abb.ability.scemodule.supportedModelsType",
    VERSION: "1.0.0",
};

export const FUNCTION_MODE = <const>{
    VIEW: "VIEW",
    EDIT: "EDIT",
    CREATE: "CREATE",
};

export const TABLE_IO_TYPE = <const>{
    INPUT: "INPUT",
    OUTPUT: "OUTPUT",
    CONDITION: "CONDITION",
};

export const FUNCTION_SAVE_TYPE = <const>{
    CREATE: "CREATE",
    UPDATE: "UPDATE",
};

export const MODEL = <const>{
    DEFAULT_MODEL_ID: "abb.ability.conditionMonitor",
};

export const TABLE_ROW_CONSTANTS = <const>{
    INPUTOUTPUT: "INPUTOUTPUT",
    CALCULATIONOUTPUT: "CALCULATIONOUTPUT",
};

/**Export Models*/
export const EXPORT_MODELS_MODES = <const>{
    MODEL: "MODEL",
    OBJECT: "OBJECT",
    MULTPLE_OBJECTS: "MULTIPLE_OBJECTS",
};

export const IMPORT = "IMPORT";
export const EXPORT = "EXPORT";

export const GROUP_BY_SYSTEM = "systems";
export const GROUP_BY_STRUCTURE = "systemStructures";
export const GROUPING_IDENTIFIER = "systemId";
export const NAME_LOOKUP_MODEL = "abb.controlSystem.800xA.aspectObject";
export const MIN_RECORDS = 1;
export const MAX_RECORDS = 50000;
export const IS_LOGIN = "isLoggedIn";
export const DEFAULT_CONTROL_STRUCTURE = "abb.controlSystem.800xA.structure.control";
export const LEAF_DRAG_DATASET = "ObjectTree";
export const BETWEEN_OBJECT_DRAG_DATASET = "betweenObjectDragDataset";
export const NODE_MARGIN_LEFT = 15;
export const MAX_NODES = 32;
export const HIERARCHY_TOOL = "hierarchyTool";
export const STRUCTURE_SELECTION = <const>{
    NEW: 1,
    EXISTING: 0
};

export const ASSET_CRITICALITY = <const>{
    ENABLE: "ENABLE",
    DISABLE: "DISABLE",
    DELETE: "DELETE",
    CONFIGURE: "CONFIGURE",
};

export const ACTION_TYPE = <const>{
    ROOT: "ROOT",
    ADD: "ADD",
    EDIT: "EDIT",
    COPY: "COPY",
    NEW: "NEW",
    DELETE: "DELETE"
};
export const AuthScheme = {
    ability: "AbilityB2C",
    aad: "AbilityAAD",
    oidc: "OIDC",
};
export const MOCK_SYSTEM_STRUCTURE: IStructure[] = [
    {
        "model": "abb.controlSystem.800xA.structure.control",
        "name": "Control Structure",
        "tenantId": "1425353"
    },
    {
        "model": "abb.controlSystem.800xA.structure.functional",
        "name": "Functional Structure",
        "tenantId": "15262272"
    },
    {
        "model": "abb.controlSystem.800xA.structure.location",
        "name": "Location Structure",
        "tenantId": null
    },
    {
        "model": "abb.ua.device",
        "name": "OPC UA",
        "tenantId": null
    },
    {
        "model": "abb.controlSystem.800xA.structure.asset",
        "name": "Asset Structure",
        "tenantId": null
    },
    {
        "model": "abb.ua.structure",
        "name": "OPC UA Structure",
        "tenantId": null
    },
    {
        "model": "Abb.IndustrialAutomation.History.EquipmentModel",
        "name": "Equipment Model",
        "tenantId": null
    },
    {
        "model": "abb.hierarchy.structure.controlstructurecopy19",
        "name": "controlStructureCopy19",
        "tenantId": null
    }
]

export const GLOBAL_LIST = <const>{
    SUPPORTED_MODEL: "Connect Models",
    IDENTITY: "Identity",
    SUPPORTED_CONDITION_MONITORS: "Supported ConditionMonitor Models",
};

export const OVERALL_SEVERITY_FUNCTION = <const>{
    TYPE_ID: "abb.ability.function.overallSeverity",
    VERSION: "1.0.0",
    NAME: "OverallSeverity",
    ID: "OverallSeverity_0",
};

export const STRUCTURE_IDENTITY = <const>{
    MODEL: "abb.ability.structure.identity",
    TYPE: "abb.ability.Structure.identity.type",
    VERSION: "1.0.0",
};

export const MODEL_SAVE_TYPE = <const>{
    SAVE_AS: "Save As",
};

export const MAX_CHARACTERS = 150;
export const OPCUA_MODEL = "abb.ua.device";

export const SUPPORTED_CONDITION_MONITORS_INFO =
    'Upon selecting the Tenant Specific option the model will be marked specific to the tenant and it will not be visible on other tenants. Public models are visible on all the tenants.';
export const SUPPORTED_CONDITION_MONITORS_TAG = <const>{
    TENANT_SPECIFIC_TAG: 'Tenant Specific',
    PUBLIC_MODEL: 'Public',
};


export const DEFAULT_CONECT_MODELS = ["abb.generic.assetCriticalityObject","abb.ability.device","abb.ua.device","abb.controlSystem.800xA.aspectObject"];