// import { configurationToolActionTypes } from "../configurationTool/actionTypes";
// import { modelsPageActionTypes } from "../modelsPage/actionTypes";
// import { exportDialogActionTypes } from "../exportAsset/actionTypes";
import { supportedConditionMonitors } from "../supportedConditionMonitors/actionTypes";
import { settingActionTypes } from '../settings/actionTypes';


export const ActionTypes = <const>{
    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL",
    SHOW_DIALOG: "SHOW_DIALOG",
    HIDE_DIALOG: "HIDE_DIALOG",
    SHOW_ALARM_MODAL: "SHOW_ALARM_MODAL",
    HIDE_ALARM_MODAL: "HIDE_ALARM_MODAL",
    TOGGLE_ACTIVE_VIEW: "TOGGLE_ACTIVE_VIEW",
    HIDE_MESSAGE_MODAL: "HIDE_MESSAGE_MODAL",
    SHOW_MESSAGE_MODAL: "SHOW_MESSAGE_MODAL",
    TOGGLE_SPINNER_MODAL: "TOGGLE_SPINNER_MODAL",

    UPDATE_ASSET_CONFIG_MODEL: "UPDATE_ASSET_CONFIG_MODEL",
    CLEAR_ASSET_CONFIG_MODEL_DETAILS: "CLEAR_ASSET_CONFIG_MODEL_DETAILS",

    GET_ASSET_TABLE_DATA_API: "GET_ASSET_TABLE_DATA_API",
    GET_ASSET_TABLE_DATA_STORE: "GET_ASSET_TABLE_DATA_STORE",

    FETCH_ASSETS_LIST: "FETCH_ASSETS_LIST",
    UPDATE_ASSETS_LIST: "UPDATE_ASSETS_LIST",
    ADD_NEW_COMPUTE_MODEL: "ADD_COMPUTE_MODEL",
    CHANGE_ACTIVE_MODEL: "UPDATE_ACTIVE_MODEL",
    MARK_COMPUTE_MODEL_DIRTY: "MARK_COMPUTE_MODEL_DIRTY",
    TOGGLE_EDIT_MODE: "TOGGLE_EDIT_MODE",
    OBJECT_LIST_SEARCH: "OBJECT_LIST_SEARCH",
    FUNCTION_LIST_SEARCH: "FUNCTION_LIST_SEARCH",
    GET_MODEL_USED_BY_INSTANCES:"GET_MODEL_USED_BY_INSTANCES",

    // Table
    TABLE_UPDATE_CANVAS_JSON: "TABLE_UPDATE_CANVAS_JSON",
    ADD_ASSET_OBJECT_REQUEST: "ADD_ASSET_OBJECT_REQUEST",
    ADD_ASSET_OBJECT_SUCCESS: "ADD_ASSET_OBJECT_SUCCESS",
    ADD_ASSET_OBJECT_FAILURE: "ADD_ASSET_OBJECT_FAILURE",
    TABLE_IO_CONNECTION_REQUEST: "TABLE_IO_CONNECTION_REQUEST",
    TABLE_IO_CONNECTION_SUCCESS: "TABLE_IO_CONNECTION_SUCCESS",
    TABLE_IO_CONNECTION_FAILURE: "TABLE_IO_CONNECTION_FAILURE",
    TABLE_CHECKBOX_CLICK: "TABLE_CHECKBOX_CLICK",
    TABLE_REMOVE_IO_CONNECTION_REQUEST: "TABLE_REMOVE_IO_CONNECTION_REQUEST",

    SAVE_COMPUTE_MODEL_API: "SAVE_COMPUTE_MODEL_API",
    SAVE_COMPUTE_MODEL_STORE: "SAVE_COMPUTE_MODEL_STORE",
    SAVE_COMPUTE_MODEL_API_ERROR: "SAVE_COMPUTE_MODEL_API_ERROR",

    GET_COMPUTE_MODELS_API: "GET_COMPUTE_MODELS_API",
    GET_COMPUTE_MODELS_STORE: "GET_COMPUTE_MODELS_STORE",
    DELETE_COMPUTE_MODEL_API: "DELETE_COMPUTE_MODEL_API",
    DELETE_COMPUTE_MODEL_STORE: "DELETE_COMPUTE_MODEL_STORE",
    DELETE_COMPUTE_MODEL_API_ERROR: "DELETE_COMPUTE_MODEL_API_ERROR",
    UPDATE_ACTIVE_MODEL_JSON: "UPDATE_ACTIVE_MODEL_JSON",
    UPDATE_MODEL_USED_BY_INSTANCES: "UPDATE_MODEL_USED_BY_INSTANCES",
    HANDLE_SIDEBAR_TOGGLE: "HANDLE_SIDEBAR_TOGGLE",
    GET_ALL_PERMISSION_MODEL: "GET_ALL_PERMISSION_MODEL",
    UPDATE_MODEL_PERMISSION: "UPDATE_MODEL_PERMISSION",

    // Asset Reducer
    ASSET_CATEGORY_LIST_REQUEST: "ASSET_CATEGORY_LIST_REQUEST",
    ASSET_CATEGORY_LIST_SUCCESS: "ASSET_CATEGORY_LIST_SUCCESS",
    ASSET_CATEGORY_LIST_FAILURE: "ASSET_CATEGORY_LIST_FAILURE",

    ASSET_CATEGORY_SELECTION: "ASSET_CATEGORY_SELECTION",

    GET_SYSTEM_STRUCTURE_MODELS: "GET_SYSTEM_STRUCTURE_MODELS",
    GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS:
        "GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS",
    GET_SYSTEMS_BASED_ON_CONNECT_MODEL: "GET_SYSTEMS_BASED_ON_CONNECT_MODEL",
    UPDATE_SYSTEM_STRUCTURE_DETAILS: "UPDATE_SYSTEM_STRUCTURE_DETAILS",
    UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS:
        "UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS",
    UPDATE_GROUP_BY: "UPDATE_GROUP_BY",
    UPDATE_STRUCTURE: "UPDATE_STRUCTURE",
    UPDATE_SYSTEM: "UPDATE_SYSTEM",
    GET_NEW_SYSTEM_STRUCTURES_REQUEST: "GET_NEW_SYSTEM_STRUCTURES_REQUEST",
    HANDLE_NEW_TREE_LIST_NODE_TOGGLE: "HANDLE_NEW_TREE_LIST_NODE_TOGGLE",
    GET_NEW_ASSET_INSTANCE_LIST_REQUEST: "GET_NEW_ASSET_INSTANCE_LIST_REQUEST",
    SEARCH_ASSET: "SEARCH_ASSET",
    LOAD_STRUCTURE_LIST_REQUEST: "LOAD_STRUCTURE_LIST_REQUEST",

    GET_ALL_PERMISSION_ASSET: "GET_ALL_PERMISSION_ASSET",
    UPDATE_ASSET_PERMISSION: "UPDATE_ASSET_PERMISSION",
    GET_FILTER_ASSET_MODEL_TYPE_REQUEST: "GET_FILTER_ASSET_MODEL_TYPE_REQUEST",
    UPDATE_FILTER_ASSET_MODEL_TYPE: "UPDATE_FILTER_ASSET_MODEL_TYPE",
    UPDATE_FILTER_ASSET_QUERY: "UPDATE_FILTER_ASSET_QUERY",
    UPDATE_SELECTED_PARENT_TREE_NODE: "UPDATE_SELECTED_PARENT_TREE_NODE",
    UPDATE_SELECTED_NODE_PARENT_LIST: "UPDATE_SELECTED_NODE_PARENT_LIST",

    GET_SYSTEMS_REQUEST: "GET_SYSTEMS_REQUEST",
    GET_SYSTEMS_SUCCESS: "GET_SYSTEMS_SUCCESS",
    GET_SYSTEMS_FAILURE: "GET_SYSTEMS_FAILURE",
    GET_EDGES_REQUEST: "GET_EDGES_REQUEST",
    GET_EDGES_SUCCESS: "GET_EDGES_SUCCESS",
    GET_EDGES_FAILURE: "GET_EDGES_FAILURE",
    GET_OBJECT_BY_EDGES_REQUEST: "GET_OBJECT_BY_EDGES_REQUEST",
    GET_OBJECT_BY_EDGES_SUCCESS: "GET_OBJECT_BY_EDGES_SUCCESS",
    GET_OBJECT_BY_EDGES_FAILURE: "GET_OBJECT_BY_EDGES_FAILURE",
    GET_STRUCTURES_REQUEST: "GET_STRUCTURES_REQUEST",
    GET_STRUCTURES_SUCCESS: "GET_STRUCTURES_SUCCESS",
    GET_STRUCTURES_FAILURE: "GET_STRUCTURES_FAILURE",
    GET_SYSTEM_STRUCTURES_REQUEST: "GET_SYSTEM_STRUCTURES_REQUEST",
    GET_SYSTEM_STRUCTURES_SUCCESS: "GET_SYSTEM_STRUCTURES_SUCCESS",
    GET_SYSTEM_STRUCTURES_FAILURE: "GET_SYSTEM_STRUCTURES_FAILURE",
    GET_ASSET_INSTANCE_LIST_REQUEST: "GET_ASSET_INSTANCE_LIST_REQUEST",
    GET_ASSET_INSTANCE_LIST_SUCCESS: "GET_ASSET_INSTANCE_LIST_SUCCESS",
    GET_ASSET_INSTANCE_LIST_FAILURE: "GET_ASSET_INSTANCE_LIST_FAILURE",
    UPDATE_SELECTED_TREE_NODE: "UPDATE_SELECTED_TREE_NODE",
    UPDATE_TREE_LIST_NODES: "UPDATE_TREE_LIST_NODES",
    HANDLE_TREE_LIST_NODE_TOGGLE: "HANDLE_TREE_LIST_NODE_TOGGLE",
    TOGGLE_ASSET_PAGE: "TOGGLE_ASSET_PAGE",
    UPDATE_NORMALIZED_TREE_LIST_NODE: "UPDATE_NORMALIZED_TREE_LIST_NODE",
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST:
        "HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST",
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS:
        "HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS",
    HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE:
        "HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE",
    HANDLE_INSTANCE_MONITORING: "HANDLE_INSTANCE_MONITORING",
    UPDATE_SELECTED_MENU_ITEM: "UPDATE_SELECTED_MENU_ITEM",
    UPDATE_INSTANCE_MONITORING_LIST: "UPDATE_INSTANCE_MONITORING_LIST",
    START_CRITICALITY_MONITORING: "START_CRITICALITY_MONITORING",

    UPDATE_ACTIVE_MODEL_DATA: "UPDATE_ACTIVE_MODEL_DATA",
    UPDATE_SUPPORTED_MODEL_DATA: "UPDATE_SUPPORTED_MODEL_DATA",

    GET_ALL_COMPUTE_MODELS_API: "GET_ALL_COMPUTE_MODELS_API",
    GET_ALL_COMPUTE_MODELS_STORE: "GET_ALL_COMPUTE_MODELS_STORE",
    UPDATE_ASSET_CRITICALITY_MODELS: "UPDATE_ASSET_CRITICALITY_MODELS",
    UPDATE_SELECTED_CRITICALITY_MODEL: "UPDATE_SELECTED_CRITICALITY_MODEL",
    GET_MATCHING_COMPUTE_MODELS_API: "GET_MATCHING_COMPUTE_MODELS_API",
    GET_MATCHING_COMPUTE_MODELS_STORE: "GET_MATCHING_COMPUTE_MODELS_STORE",
    CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG: "CHANGE_ACTIVE_MODEL_INSTANCE_CONFIG",
    SAVE_INSTANCE_COMPUTE_MODEL: "SAVE_INSTANCE_COMPUTE_MODEL",
    DELETE_INSTANCE_COMPUTE_MODEL: "DELETE_INSTANCE_COMPUTE_MODEL",
    ADD_TO_SELECTED_INSTANCE: "ADD_TO_SELECTED_INSTANCE",
    REMOVE_FROM_SELECTED_INSTANCE: "REMOVE_FROM_SELECTED_INSTANCE",
    UPDATE_ACTIVE_TAB_INSTANCE_CONFIG: "UPDATE_ACTIVE_TAB_INSTANCE_CONFIG",
    CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG: "CHANGE_ACTIVE_PAGE_INSTANCE_CONFIG",
    CHANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG:
        "HANGE_ENTERIES_PER_PAGE_INSTANCE_CONFIG",
    HANLDE_FILTER_ASSET_TABLE_DATA: "HANLDE_FILTER_ASSET_TABLE_DATA",
    HANLDE_SORT_ASSET_TABLE_DATA: "HANDLE_SORT_ASSET_TABLE_DATA",
    HANDLE_CONFIGURED_INSTANCE_JSON: "HANDLE_CONFIGURED_INSTANCE_JSON",
    HANDLE_LOADING_CONFIGURED_INSTANCE: "HANDLE_LOADING_CONFIGURED_INSTANCE",
    HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE:
        "HANDLE_ASSET_TOGGLE_UNCONFIGURED_INSTANCE",
    SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS:
        "SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS",
    SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR:
        "SAVE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR",
    DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS:
        "DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_SUCCESS",
    DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR:
        "DELETE_COMPUTE_MODEL_INSTANCE_CONFIG_ERROR",
    HANDLE_VIEW_MODE_CHANGE_INSTANCE_CONFIG:
        "HANDLE_VIEW_MODE_CHANGE_INSTANCE_CONFIG",
    HANDLE_INSTANCE_DROP_ACTION: "HANDLE_INSTANCE_DROP_ACTION",

    TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG:
        "TABLE_UPDATE_CANVAS_JSON_INSTANCE_CONFIG",
    HANDLE_CONFIGURE_BUTTON_CLICK: "HANDLE_CONFIGURE_BUTTON_CLICK",
    ADD_ASSET_OBJECT_REQUEST_INSTANCE_CONFIG:
        "ADD_ASSET_OBJECT_REQUEST_INSTANCE_CONFIG",
    ADD_ASSET_OBJECT_SUCCESS_INSTANCE_CONFIG:
        "ADD_ASSET_OBJECT_SUCCESS_INSTANCE_CONFIG",
    ADD_ASSET_OBJECT_FAILURE_INSTANCE_CONFIG:
        "ADD_ASSET_OBJECT_FAILURE_INSTANCE_CONFIG",
    TABLE_IO_CONNECTION_REQUEST_INSANCE_CONFIG:
        "TABLE_IO_CONNECTION_REQUEST_INSANCE_CONFIG",
    TABLE_IO_CONNECTION_SUCCESS_INSANCE_CONFIG:
        "TABLE_IO_CONNECTION_SUCCESS_INSANCE_CONFIG",
    TABLE_IO_CONNECTION_FAILURE_INSANCE_CONFIG:
        "TABLE_IO_CONNECTION_FAILURE_INSANCE_CONFIG",
    TABLE_CHECKBOX_CLICK_INSANCE_CONFIG: "TABLE_CHECKBOX_CLICK_INSANCE_CONFIG",
    TABLE_REMOVE_IO_CONNECTION_REQUEST_INSANCE_CONFIG:
        "TABLE_REMOVE_IO_CONNECTION_REQUEST_INSANCE_CONFIG",
    // functions
    CREATE_FUNCTION: "CREATE_FUNCTION",
    DRAG_DROP_FUNCTION: "DRAG_DROP_FUNCTION",
    GET_FUNCTION_TYPES: "GET_FUNCTION_TYPES",
    SET_FUNCTION_TYPES: "SET_FUNCTION_TYPES",
    ERROR_GET_FUNCTION_TYPES: "ERROR_GET_FUNCTION_TYPES",
    GET_SELECTED_FUNCTION_TYPE: "GET_SELECTED_FUNCTION_TYPE",
    UPDATE_SELECTED_FUNCTION_TYPE: "UPDATE_SELECTED_FUNCTION_TYPE",
    HANDLE_FUNCTION_DELETE: "HANDLE_FUNCTION_DELETE",
    SUCCESS_FUNCTION_DELETE: "SUCCESS_FUNCTIO_DELETE",
    ERROR_FUNCTION_DELETE: "ERROR_FUNCTION_DELETE",
    SAVE_FUNCTION: "SAVE_FUNCTION",
    ERROR_LOADING_DATA: "ERROR_SAVE_FUNCTION",
    SUCCESS_LOADING_DATA: "SUCCESS_SAVE_FUNCTION",
    SHOW_NOTIFICATION_MODAL: "SHOW_NOTIFICATION_MODAL",
    HIDE_NOTIFICATIOM_MODAL: "HIDE_NOTIFICATIOM_MODAL",
    UPDATE_FUNC_ASSETS_INFO: "UPDATE_FUNC_ASSETS_INFO",
    HANDLE_POPOVER_OPEN: "HANDLE_POPOVER_OPEN",
    HANDLE_POPOVER_INFO: "HANDLE_POPOVER_INFO",
    UPDATE_FUNCTION_HEADER_TYPE_DETAILS: "UPDATE_FUNCTION_HEADER_TYPE_DETAILS",
    UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS:
        "UPDATE_FUNCTION_HEADER_TYPE_STATUS_DETAILS",
    UPDATE_CONDITION_DELETE_STATUS: "UPDATE_CONDITION_DELETE_STATUS",
    UPDATE_CALCULATION_DELETE_STATUS: "UPDATE_CALCULATION_DELETE_STATUS",
    HANDLE_FUNCTION_SAVE: "HANDLE_FUNCTION_SAVE",
    HANDLE_ASSETS_INFO: "HANDLE_ASSETS_INFO",
    UPDATE_FUNCTION_ENDPOINT: "UPDATE_FUNCTION_ENDPOINT",
    HANDLE_FUNCTION_MODE: "HANDLE_FUNCTION_MODE",
    SUCCESS_FUNCTION_SAVE: "SUCCESS_FUNCTION_SAVE",
    CHECK_CALC_FIELD_VALIDATION: "CHECK_CALC_FIELD_VALIDATION",
    HANDLE_IS_FUNCTION_DIRTY: "HANDLE_IS_FUNCTION_DIRTY",
    HANDLE_FUNCTION_ERROR_FLAG: "HANDLE_FUNCTION_ERROR_FLAG",

    GET_ALL_PERMISSION_FUNCTION: "GET_ALL_PERMISSION_FUNCTION",
    UPDATE_FUNCTION_PERMISSION: "UPDATE_FUNCTION_PERMISSION",

    DEFAULT_VALUE_TOOL: "DEFAULT_VALUE_TOOL",
    DEFAULT_VALUE_INSTANCE_CONFIG: "DEFAULT_VALUE_INSTANCE_CONFIG",
    HANDLE_INCLUDE_ALL_CHILD_ELEMENTS: "HANDLE_INCLUDE_ALL_CHILD_ELEMENTS",
    HANDLE_TABLE_TOTAL_RECORDS: "HANDLE_TABLE_TOTAL_RECORDS",
    UPDATE_ALARM_MODAL_DRAG_DROP_TABLES: "UPDATE_ALARM_MODAL_DRAG_DROP_TABLES",
    RESET_PAGINATION_LOADER: "RESET_PAGINATION_LOADER",
    GET_ALARM_TYPE: "GET_ALARM_TYPE",
    SET_ALARM_TYPE: "SET_ALARM_TYPE",
    UPDATE_SELECTED_ALARM_TYPE: "UPDATE_SELECTED_ALARM_TYPE",
    UPDATE_ALARM_MAPPING_DETAILS: "UPDATE_ALARM_MAPPING_DETAILS",
    UPDATE_TAB_NAV_LIST: "UPDATE_TAB_NAV_LIST",
    CLEAR_DROP_TABLE_MAPPING: "CLEAR_DROP_TABLE_MAPPING",

    // ...configurationToolActionTypes,
    // ...modelsPageActionTypes,
    // ...exportDialogActionTypes,
    CUSTOM_VARIABLE_DELETE: "CUSTOM_VARIABLE_DELETE",
    DROP_TREE_NODE: "DROP_TREE_NODE",
    UPDATE_NEW_HIERARCHY_NODE: "UPDATE_NEW_HIERARCHY_NODE",
    DROP_TREELIST_NODE_TOGGLE_ACTION: "DROP_TREELIST_NODE_TOGGLE_ACTION",
    GET_NEW_SYSTEM_STRUCTURE_DROP_NODE_REQUEST: "GET_NEW_SYSTEM_STRUCTURE_DROP_NODE_REQUEST",
    CLEAR_HIERARCHY_DROP_NODE_REQUEST: "CLEAR_HIERARCHY_DROP_NODE_REQUEST",
    SAVE_HIERARCHY_DROP_NODE_REQUEST: "SAVE_HIERARCHY_DROP_NODE_REQUEST",
    UPDATE_TREE_LIST_DROP_NODES: "UPDATE_TREE_LIST_DROP_NODES",
    SET_EXISTING_STRUCTURE: "SET_EXISTING_STRUCTURE",
    ADD_CHILD_NODES_TO_PARENT_DROP_NODE_REQUEST: "ADD_CHILD_NODES_TO_PARENT_DROP_NODE_REQUEST",
    REMOVE_NODE_FROM_TREE: "REMOVE_NODE_FROM_TREE",
    UPDATE_SELECTED_TREE_NODE_DROP: "UPDATE_SELECTED_TREE_NODE_DROP",
    ADD_DEFAULT_NODE: "ADD_DEFAULT_NODE",
    LOAD_TREE_HIERARCHY_LIST_REQUEST: "LOAD_TREE_HIERARCHY_LIST_REQUEST",
    UPDATE_SYSTEM_STRUCTURE_DETAILS_FOR_TENANT: "UPDATE_SYSTEM_STRUCTURE_DETAILS_FOR_TENANT",
    GET_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT: "GET_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT",
    CREATE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT: "CREATE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT",
    CLONE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT: "CLONE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT",
    DELETE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT: "DELETE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT",
    RESET_COMMON_HIERARCHY: "RESET_COMMON_HIERARCHY",
    LOAD_OBJECT_HIERARCHY: "LOAD_OBJECT_HIERARCHY",
    CHANGE_PARENT_REFERENCE_REQUEST: "CHANGE_PARENT_REFERENCE_REQUEST",

    // LOGIN ACTION TYPES
    LOGIN_REQUEST: "LOGIN_REQUEST",
    INITIALISE_LOGIN_PROVIDER: "INITIALISE_LOGIN_PROVIDER",
    HANDLE_USER_LOGIN: "HANDLE_USER_LOGIN",
    UPDATE_IS_LOGGED_IN: "UPDATE_IS_LOGGED_IN",
    HANDLE_LOADING_STATUS: "HANDLE_LOADING_STATUS",
    HANDLE_LOGIN_LOADING: "HANDLE_LOGIN_LOADING",
    REDIRECT_LOGIN: "REDIRECT_LOGIN",
    HANDLE_ERROR_LOGIN: "HANDLE_ERROR_LOGIN",
    //----------//
    
    UPDATE_EDGE_LIST: "UPDATE_EDGE_LIST",
    RESTORE_EDGE: "RESTORE_EDGE",
    GET_INFO_MODEL_INSTANCES: "GET_INFO_MODEL_INSTANCES",
    UPDATE_INFO_MODEL_INSTANCE: "UPDATE_INFO_MODEL_INSTANCE",
    SET_ACTIVE_SETTING_TAB:"SET_ACTIVE_SETTING_TAB",
    SAVE_INFO_MODEL_INSTANCE: "SAVE_INFO_MODEL_INSTANCE",
    DELETE_INFO_MODEL_INSTANCE: "DELETE_INFO_MODEL_INSTANCE",
    GET_TYPE_DEFINITION_REQUEST: "GET_TYPE_DEFINITION_REQUEST",
    UPDATE_NEW_DEFINITION_TYPE: "UPDATE_NEW_DEFINITION_TYPE",
    SHOW_PROGRESS: "SHOW_PROGRESS",
    HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE:
        "HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE",
    UPDATE_TYPE_JSON_CONDITION_DETAILS: "UPDATE_TYPE_JSON_CONDITION_DETAILS",
    UPDATE_ORIIGNAL_COMPUTE_MODELS: "UPDATE_ORIIGNAL_COMPUTE_MODELS",
    GET_AVAILABLE_TYPE_IDS: "GET_AVAILABLE_TYPE_IDS",
    SET_AUTHORIZE:"SET_AUTHORIZE",
    UPDATE_AVAILABLE_TYPE_IDS: "UPDATE_AVAILABLE_TYPE_IDS",
    ...supportedConditionMonitors
};
