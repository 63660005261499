import { normalize } from "normalizr";
import { Node } from "react-virtualized-tree";
import { ActionTypes } from "../actionTypes";
import {
    CategoryObjTypes,
    SystemObjTypes,
    TreeNodeType,
    NormalizeTreeNode,
    IStructure,
    ISystem,
    IAssetPermission,
    IFilteredItem,
    InstanceMonitoringType,
    IEdgeList,
} from "./types";
// import {
//     ACTION_MENU_LIST,
//     ACTION_MENU_VALUE,
//     ActionMenuValue,
// } from "../instanceConfig/types";
// import { ComputeModelToJson } from "../../transformers/ComputeModel/toJson/ComputeModelToJson";

export function getAssetCategoryListActionRequest() {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_REQUEST,
        payload: {},
    };
}
export function getAssetCategoryListActionSuccess(
    categoryList: CategoryObjTypes[]
) {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_SUCCESS,
        payload: {
            dataArr: categoryList,
        },
    };
}
export function getAssetCategoryListActionFailure() {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_LIST_FAILURE,
        payload: {
            dataArr: [],
        },
    };
}
export function handleCategorySelectionAction(
    selectedCategory: CategoryObjTypes | undefined
) {
    return <const>{
        type: ActionTypes.ASSET_CATEGORY_SELECTION,
        payload: {
            categoryObj: selectedCategory,
        },
    };
}

export function setTreeListNodesAction({
    node,
    normalizeNode,
    treeVirtualNodes,
}: {
    node: TreeNodeType;
    normalizeNode: NormalizeTreeNode;
    treeVirtualNodes: Node;
}) {
    return <const>{
        type: ActionTypes.UPDATE_TREE_LIST_NODES,
        payload: {
            data: {
                node,
                normalizeNode,
                treeVirtualNodes,
            },
        },
    };
}

export function getSystemsActionRequest() {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_REQUEST,
        payload: {},
    };
}
export function getSystemsActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function getSystemsActionFailure() {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getEdgesActionRequest() {
    return <const>{
        type: ActionTypes.GET_EDGES_REQUEST,
        payload: {},
    };
}
export function getEdgesActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_EDGES_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function getEdgesActionFailure() {
    return <const>{
        type: ActionTypes.GET_EDGES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function geObjectBytEdgesActionRequest(objectId: string) {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_REQUEST,
        payload: {
            objectId,
        },
    };
}
export function geObjectBytEdgesActionSuccess(systemArr: SystemObjTypes[]) {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_SUCCESS,
        payload: {
            data: systemArr,
        },
    };
}
export function geObjectBytEdgesActionFailure() {
    return <const>{
        type: ActionTypes.GET_OBJECT_BY_EDGES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function setSelectedTreeNodeAction(selectedNode: TreeNodeType | null) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_TREE_NODE,
        payload: {
            data: selectedNode,
        },
    };
}

export function getStructuresActionRequest(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_REQUEST,
        payload: {
            data: node,
        },
    };
}
// TODO
// fix the
export function getStructuresActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_SUCCESS,
        payload: {
            data: data,
        },
    };
}
export function getStructuresActionFailure() {
    return <const>{
        type: ActionTypes.GET_STRUCTURES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getSystemStructuresActionRequest(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_REQUEST,
        payload: {
            data: node,
        },
    };
}

// TODO
// fix the
export function getSystemStructuresActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_SUCCESS,
        payload: {},
    };
}
export function getSystemStructuresActionFailure() {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURES_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function getAssetInstanceListActionRequest(payload: {
    node: TreeNodeType;
    enteriesPerPage?: number;
    activePage?: number;
    includeAllChild?: boolean;
}) {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST,
        payload,
    };
}

// TODO
// fix the
export function getAssetInstanceListActionSuccess(data: any) {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_SUCCESS,
        payload: data,
    };
}
export function getAssetInstanceListActionFailure() {
    return <const>{
        type: ActionTypes.GET_ASSET_INSTANCE_LIST_FAILURE,
        payload: {
            data: [],
        },
    };
}
export function handleTreeListNodeToggleAction(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.HANDLE_TREE_LIST_NODE_TOGGLE,
        payload: {
            data: node,
        },
    };
}
export function handleAssetToggle() {
    return <const>{
        type: ActionTypes.TOGGLE_ASSET_PAGE,
        payload: {},
    };
}
export function updateNormalizedTreeListNode(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.UPDATE_NORMALIZED_TREE_LIST_NODE,
        payload: {
            data: node,
        },
    };
}
export function handleAssetInstanceActionMenuClick(
    selectedActionMenuObj: any
) {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST,
        payload: {
            data: selectedActionMenuObj,
        },
    };
}
export function handleAssetInstanceActionMenuClickSuccess() {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS,
        payload: {},
    };
}
export function handleAssetInstanceActionMenuClickFailure() {
    return <const>{
        type: ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE,
        payload: {},
    };
}

export function handleInstanceMonitoring() {
    return <const>{
        type: ActionTypes.HANDLE_INSTANCE_MONITORING,
    };
}

// Asset structure details
export function updateGroupBy(groupBy: string) {
    return <const>{
        type: ActionTypes.UPDATE_GROUP_BY,
        payload: {
            data: groupBy,
        },
    };
}
export function updateStructure(structure: IStructure) {
    return <const>{
        type: ActionTypes.UPDATE_STRUCTURE,
        payload: {
            data: structure,
        },
    };
}
export function updateSystem(system: ISystem) {
    return <const>{
        type: ActionTypes.UPDATE_SYSTEM,
        payload: {
            data: system,
        },
    };
}
export function getSystemStructureModel() {
    return <const>{
        type: ActionTypes.GET_SYSTEM_STRUCTURE_MODELS,
        payload: {},
    };
}
export function updateSystemStructureModels(systemStructure: IStructure[]) {
    return <const>{
        type: ActionTypes.UPDATE_SYSTEM_STRUCTURE_DETAILS,
        payload: {
            data: systemStructure,
        },
    };
}

export function getConnectDetailsFromIdentityModel() {
    return <const>{
        type: ActionTypes.GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        payload: {},
    };
}
export function updateConnectDetailsFromIdentityModels(system: ISystem[]) {
    return <const>{
        type: ActionTypes.UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        payload: {
            data: system,
        },
    };
}
// TO DO - Get Systems TreeLIst
export function getSystemsBasedOnConnectModelRequest(details: any) {
    return <const>{
        type: ActionTypes.GET_SYSTEMS_BASED_ON_CONNECT_MODEL,
        payload: {
            data: details,
        },
    };
}
export function getNewSystemStructuresActionRequest(details: any) {
    return <const>{
        type: ActionTypes.GET_NEW_SYSTEM_STRUCTURES_REQUEST,
        payload: {
            data: details,
        },
    };
}

export function handleNewTreeListNodeToggleAction(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.HANDLE_NEW_TREE_LIST_NODE_TOGGLE,
        payload: {
            data: node,
        },
    };
}

export function getNewAssetInstanceListActionRequest(payload: {
    node: TreeNodeType;
    type: string;
    enteriesPerPage?: number;
    activePage?: number;
    includeAllChild?: boolean;
}) {
    return <const>{
        type: ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST,
        payload,
    };
}

export function searchAsset(text: string) {
    return <const>{
        type: ActionTypes.SEARCH_ASSET,
        payload: {
            data: text,
        },
    };
}

export function getAllPermisssionForAssetAction() {
    return {
        type: ActionTypes.GET_ALL_PERMISSION_ASSET,
    };
}

export function updateAssetPermission(payload: IAssetPermission) {
    return {
        type: ActionTypes.UPDATE_ASSET_PERMISSION,
        payload,
    };
}
// Filter Query Action
export function setSelectedParentTreeNodeAction(selectedNodeId: any) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_PARENT_TREE_NODE,
        payload: {
            data: selectedNodeId,
        },
    };
}

export function selectedNodeParentListAction(nodes: string[]) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_NODE_PARENT_LIST,
        payload: {
            data: nodes,
        },
    };
}

export function getFilterAssetModelTypeRequest() {
    return <const>{
        type: ActionTypes.GET_FILTER_ASSET_MODEL_TYPE_REQUEST,
    };
}

export function updateFilterAssetModelType(values: {
    assetModelTypes: string[];
    assetTypes: string[];
}) {
    return <const>{
        type: ActionTypes.UPDATE_FILTER_ASSET_MODEL_TYPE,
        payload: {
            data: values,
        },
    };
}

export function updateFilterAssetQuery(
    filteredItems: IFilteredItem[],
    applyFilter: boolean
) {
    return <const>{
        type: ActionTypes.UPDATE_FILTER_ASSET_QUERY,
        payload: {
            filteredItems: filteredItems,
            applyFilter: applyFilter,
        },
    };
}

export const handleCriticalityMonitoring = (payload: any) => {
    return <const>{
        type: ActionTypes.START_CRITICALITY_MONITORING,
        payload,
    };
};

export const deleteCustomVariableRequest = (payload: any) => {
    return <const>{
        type: ActionTypes.CUSTOM_VARIABLE_DELETE,
        payload,
    };
};

// Custom Object Hierarchy Tool
export function dropTreeNode(selectedNode: TreeNodeType) {
    return <const>{
        type: ActionTypes.DROP_TREE_NODE,
        payload: {
            data: selectedNode,
        },
    };
}

export function updateNewHierarchyNode(treeNode: Node) {
    return <const>{
        type: ActionTypes.UPDATE_NEW_HIERARCHY_NODE,
        payload: {
            data: treeNode
        },
    };
}

export function dropTreeListNodeToggleAction(node: TreeNodeType) {
    return <const>{
        type: ActionTypes.DROP_TREELIST_NODE_TOGGLE_ACTION,
        payload: {
            data: node,
        },
    };
}

export function getNewSystemStructuresDropNodeRequest(details: any) {
    return <const>{
        type: ActionTypes.GET_NEW_SYSTEM_STRUCTURE_DROP_NODE_REQUEST,
        payload: {
            data: details,
        },
    };
}

export function clearHierarchyDropNodeRequest() {
    return <const>{
        type: ActionTypes.CLEAR_HIERARCHY_DROP_NODE_REQUEST
    };
}

export function saveHierarchyDropNodeRequest(details: any) {
    return <const>{
        type: ActionTypes.SAVE_HIERARCHY_DROP_NODE_REQUEST,
        payload: {
            data: details,
        },
    };
}

export function setTreeListDropNodesAction({
    // node,
    // normalizeNode,
    treeVirtualNodes,
}: {
    treeVirtualNodes: Node;
}) {
    return <const>{
        type: ActionTypes.UPDATE_TREE_LIST_DROP_NODES,
        payload: {
            data: {
                // node,
                // normalizeNode,
                treeVirtualNodes,
            },
        },
    };
}

export function setExistingStructure(structure: IStructure) {
    return <const>{
        type: ActionTypes.SET_EXISTING_STRUCTURE,
        payload: {
            data: structure,
        }
    }
}
export function addChildNodesToParentDropNodeRequest(node: TreeNodeType, childNodes: any, name: string, actionType: string) {
    return <const>{
        type: ActionTypes.ADD_CHILD_NODES_TO_PARENT_DROP_NODE_REQUEST,
        payload: {
            node,
            childNodes,
            name,
            actionType
        }
    }
}
export function changeParentReferenceRequest(payload: {parentNode: TreeNodeType, droppedNode: TreeNodeType, childNodes: any}) {
    return <const> {
        type: ActionTypes.CHANGE_PARENT_REFERENCE_REQUEST,
        payload,
    }
}
export function setSelectedTreeNodeDropAction(selectedNode: TreeNodeType | null) {
    return <const>{
        type: ActionTypes.UPDATE_SELECTED_TREE_NODE_DROP,
        payload: {
            data: selectedNode,
        },
    };
}

export function removeNodeFromTreeRequest(nodeId: any) {
    return <const>{
        type: ActionTypes.REMOVE_NODE_FROM_TREE,
        payload: {
            nodeId
        }
    }
}

export function addDefaultNewNodeTreeRequest(node: any) {
    return <const>{
        type: ActionTypes.ADD_DEFAULT_NODE,
        payload: {
            node
        }
    }
}

export function loadStructureListRequest(value: boolean) {
    return <const>{
        type: ActionTypes.LOAD_STRUCTURE_LIST_REQUEST,
        payload: value
    }
}

export function loadTreeHierarchyListRequest(value: boolean) {
    return <const>{
        type: ActionTypes.LOAD_TREE_HIERARCHY_LIST_REQUEST,
        payload: value
    }
}

// New API Integration
export function updateSystemStructureModelsForTenant(systemStructure: IStructure[]) {
    return <const>{
        type: ActionTypes.UPDATE_SYSTEM_STRUCTURE_DETAILS_FOR_TENANT,
        payload: {
            data: systemStructure,
        },
    };
}

export function getNewSystemStructureHierarchyForTenantRequest() {
    return <const>{
        type: ActionTypes.GET_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT
    }
}

export function createNewSystemStructureHierarchyForTenantRequest(name: string, modelId: string) {
    return <const>{
        type: ActionTypes.CREATE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT,
        payload: {
            name,
            modelId
        }
    }
}

export function cloneNewSystemStructureHierarchyForTenantRequest(cloneStructureName: string, clonedStructureModelId: string, originSouceId: string) {
    return <const>{
        type: ActionTypes.CLONE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT,
        payload: {
            cloneStructureName,
            clonedStructureModelId,
            originSouceId
        }
    }
}

export function deleteNewSystemStructureHierarchyForTenantRequest(modelId: string) {
    return <const>{
        type: ActionTypes.DELETE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT,
        payload: modelId
    }
}
export function resetCommonHierarchy() {
    return <const>{
        type: ActionTypes.RESET_COMMON_HIERARCHY
    }
}
export function restoreEdgeRequest(value: IEdgeList) {
    return <const>{
        type: ActionTypes.RESTORE_EDGE,
        payload: {
            data: value,
        },
    };
}

export function loadObjectHierarchy(value: boolean) {
    return <const>{
        type: ActionTypes.LOAD_OBJECT_HIERARCHY,
        payload: value
    };
}